
import { Vue, Component, Emit } from 'vue-property-decorator';
import BScroll from '@better-scroll/core';
import MouseWheel from '@better-scroll/mouse-wheel'
import BetterScroll from 'better-scroll';

BScroll.use(MouseWheel);

const STATE_HIDE = 0;
const STATE_SHOW = 1;

const COMPONENT_NAME = 'picker';
const EVENT_SELECT = 'select';
const EVENT_CANCEL = 'cancel';
const EVENT_CHANGE = 'change';

const DATA1 = [
  { text: 0, value: 0 },
  { text: 1, value: 1 },
  { text: 2, value: 2 },
  { text: 3, value: 3 },
  { text: 4, value: 4 },
  { text: 5, value: 5 },
  { text: 6, value: 6 },
  { text: 7, value: 7 },
  { text: 8, value: 8 },
  { text: 9, value: 9 },
];

const DATA2 = [{ text: 'FT', value: 'FT' }];
const DATA4 = [{ text: 'IN', value: 'IN' }];


@Component
export default class Picker extends Vue {
  name: string = COMPONENT_NAME;
  state: number = STATE_HIDE;
  selectedIndexPair: number[] = [0, 0, 0, 0, 0];
  currentIndexPair: number[] = [0, 0, 0, 0, 0];
  selectedText: string = 'open';
  pickerData: any[] = [DATA1, DATA2, DATA1, DATA4];
  wheels: any[] = []; // Assuming wheels is dynamically created

  testing() {
    console.log(this.wheels.map(wheel => wheel.getSelectedIndex()));
  }

  _confirm() {
    this.wheels.forEach(wheel => wheel.restorePosition());
    this.hide();
    this.selectedIndexPair = this.wheels.map(wheel => wheel.getSelectedIndex());
    this.selectedText = this.pickerData
      .map((data, i) => `${data[this.selectedIndexPair[i]].text}-${this.selectedIndexPair[i]}`)
      .join('__');
    this.$emit(EVENT_SELECT, this.selectedIndexPair);
  }

  @Emit(EVENT_CANCEL)
  _cancel() {
    this.wheels.forEach(wheel => wheel.restorePosition());
    this.hide();
  }

  show() {
    if (this.state === STATE_SHOW) return;
    this.state = STATE_SHOW;
    if (!this.wheels.length) {
      this.$nextTick(() => {
        let wheelWrapper = this.$refs.wheelWrapper;
        for (let i = 0; i < this.pickerData.length; i++) {
          this._createWheel(wheelWrapper, i);
        }
      });
    }
  }

  hide() {
    this.state = STATE_HIDE;
  }
    _createWheel(wheelWrapper, i) {
      if (!this.wheels[i]) {
        this.wheels[i] = new BScroll(wheelWrapper.children[i], {
          wheel: {
            selectedIndex: this.selectedIndexPair[i],
            wheelWrapperClass: 'wheel-scroll',
            wheelItemClass: 'wheel-item',
            },
      mouseWheel: {
      speed: 20,
      invert: false,
      easeTime: 300
    },
      probeType: 3,
    });
        this.wheels[i].on('scrollEnd', () => {
          this.currentIndexPair  = this.wheels.map(wheel => wheel.getSelectedIndex());
          this.$emit(EVENT_CHANGE, i,  this.wheels[i].getSelectedIndex());
          console.log(this.currentIndexPair);
        });
      } else {
        this.wheels[i].refresh();
      }

      return this.wheels[i];
    }
}
