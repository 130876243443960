import { render, staticRenderFns } from "./ValueColumn.vue?vue&type=template&id=6cd22c5a&"
import script from "./ValueColumn.vue?vue&type=script&lang=ts&"
export * from "./ValueColumn.vue?vue&type=script&lang=ts&"
import style0 from "./ValueColumn.vue?vue&type=style&index=0&id=6cd22c5a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports